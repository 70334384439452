import React from 'react';
import '../../styles/CWL.css'

function CWL() {
  return (
    <div className="custom-container">
        <h5>THE CATHOLIC WOMEN'S LEAGUE at ST. BERNADETTE PARISH</h5><br/>
        <div className="image-container">
            <img alt="=catholic women's league" className="image-border cwl-image" src={require('../../assets/img10.jpg')}/>
        </div>
        <br/>
        The <b>Catholic Women's League</b> is a national organization rooted in Gospel values calling its members to holiness through service to the people of God.
        <br/><br/>
        <p className="align-left">
        <b>The objectives of the League are to unite Catholic women of Canada:</b><br/>
        1. To achieve individual and collective spiritual development.<br/>
        2. To promote the teachings of the Catholic church.<br/>
        3. To exemplify the Christian ideal in home and family life.<br/>
        4. To protect the sanctity of life.<br/>
        5. To enhance the role of women in church and society.<br/>
        6. To recognize the human dignity of all people everywhere.<br/>
        7. To uphold and defend Christian education and values in the modern world.<br/>
        8. To contribute to the understanding and growth of religious freedom, social justice, peace and harmony.
        </p>
        The <b>St. Bernadette CWL meetings</b> are on the <b>second Thursday</b> of <b>each month</b> at <b>7:00 p.m.</b> in Room A of the St. Bernadette Parish Centre. Occasionally, in lieu of a meeting, we will arrange social events, such as our annual Fashion Show, or our Christmas celebration.
        <br/><br/>
        For more information or to join the St. Bernadette CWL, <br/>
        please contact us at <b>778-238-0525</b>
        <br/><br/>
        <b>Why Should I Join?</b><br/><br/>
        <b>Time is Precious</b><br/>
        If you would like to be part of something worthwhile that makes the best use of your time and talents, consider the CWL. Your commitment could be as easy as maintaining an inexpensive yearly membership, even when you are unable to attend meeting or other events. As time permits, you may enjoy helping with one project. Prayer and a spirit of joy are important parts of CWL projects. Fresh ideas are always welcome; share them with the new friends that await you. Life is a series of new beginnings; make the CWL a new beginning for you!
        <br/><br/>
        <b>Family is Important to Me</b><br/>
        The CWL is an organization that recognizes and celebrates healthy family life. Your primary role as a parent, provider and caregiver is respected and honoured by the CWL. Helping other people in your community allows you to live your Christian values and be a positive role model for your family. You can take part in activities and fundraising efforts that promote family life and lovingly support life in its entirety, from fertilization to natural death. You can apply for educational bursaries. Your membership adds power to the CWL’s voice as the League lobbies government on social justice issues on behalf of a national sisterhood of Catholic women.
        <br/><br/>
        <b>How Can I Enrich my Faith?</b><br/>
        The CWL joins women from all across Canada in an organization that can foster and celebrate your Catholic faith. The CWL is rooted in gospel values and calls you and all members to holiness through service to all people of God. You are invited to join League sisters as you pray for one another and walk the gospel road together. Special CWL occasions are often celebrated with the Eucharist. The CWL motto is “For God and Canada”. Mary is honoured as Our Lady of Good Counsel. You are encouraged to deepen your spiritual understanding and study Catholic teachings – an important CWL initiative. As a CWL member, you joyfully affirm that God’s presence blesses every aspect of ordinary life, including meetings, volunteer projects and celebrations.
        <br/><br/>
        <b>I Can Make a Difference</b><br/>
        If you hear a calling to advocate for human rights and social justice, add your voice and support to a national sisterhood of Catholic women who promote awareness and respond to political and social issues that affect all Canadians. You can offer welcome and comfort to the vulnerable, the needy and the marginalized. You can take an active role in the creation of resolutions that are presented to government annually on issues that impact families and communities at home and abroad. You can improve your organization and leadership skills through CWL training and service possibilities. Through the CWL you become part of the World Union of Catholic Women’s Organizations representing Catholic women and the church around the globe. CWL outreach supports many initiatives in developing countries. Through your efforts, prayerful service, and sharing of your gifts, you can be blessed with grace-filled moments. As the prayer of St. Francis says, “for it is in giving that we receive.”
        <br/><br/>
        <p className="align-left">
        <b>Important Links:</b><br/>
        Vancouver Council: <a href="https://vancouvercwl.ca/">www.vancouvercwl.ca</a><br/>
        Provincial Council: <a href="https://bcyukoncwl.com">www.bcyukoncwl.com</a><br/>
        Canadian Catholic Organization for Development and Peace (CCODP): <a href="https://devp.org">www.devp.org</a><br/>
        Canadian Conference of Catholic Bishops (CCCB): <a href="https://cccb.ca">www.cccb.ca</a><br/>
        CNEWA-Canada: <a href="http://www.cnewa.ca">www.cnewa.ca</a><br/>
        Catholic Organization for Life and Family (COLF): <a href="https://colf.ca">www.colf.ca</a><br/>
        Coady International Institute: <a href="https://coady.stfx.ca">www.coady.stfx.ca</a><br/>
        Euthanasia Prevention Coalition: <a href="https://epcc.ca">www.epcc.ca</a><br/>
        The Vatican: <a href="http://www.vatican.va">www.vatican.va</a><br/>
        World Union of Catholic Women’s Organizations (WUCWO): <a href="https://wucwo.org">www.wucwo.org</a>
        </p>
    </div>
  );
}

export default CWL;