import React from 'react';
import ReactDOM from 'react-dom';

// Pages
import Home from './components/Home/Home';
import Mass_Confession from './components/Mass_Confession/Mass_Confession'
import Bulletin from './components/Bulletin/Bulletin'
import Parish_Groups from './components/Parish_Groups/Parish_Groups'
import Sacraments from './components/Sacraments/Sacraments'
import PREP from './components/PREP/PREP'
import RCIA from './components/RCIA/RCIA'
import Donate from './components/Donate/Donate'
import Contact from './components/Contact/Contact'
import Calendar from './components/Calendar/Calendar'
import TNC from './components/TNC/TNC'

// Parish Groups
import Altar_Serving from './components/Parish_Groups/Altar_serving'
import Bible_Study_Group from './components/Parish_Groups/Bible_Study_Group'
import Bingo from './components/Parish_Groups/Bingo'
import CWL from './components/Parish_Groups/CWL'
import Golden_Bees from './components/Parish_Groups/Golden_Bees'
import Knight_of_Columbus from './components/Parish_Groups/Knight_of_Columbus'
import Legion_of_Mary from './components/Parish_Groups/Legion_of_Mary'
import Liturgy_of_the_word from './components/Parish_Groups/Liturgy_of_the_word'
import Prayer_Group from './components/Parish_Groups/Prayer_Group'
import SOSVDP from './components/Parish_Groups/SOSVDP'
import YM from './components/Parish_Groups/YM'

// Other Components
import Header from './components/Header'
import Footer from './components/Footer'

// Styles
import './styles/Main.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap'

import {BrowserRouter, Route} from 'react-router-dom'
//import routes from './components/Router/Router'

import * as serviceWorker from './serviceWorker';

//ReactDOM.render(<Home />, document.getElementById('root'));
ReactDOM.render(
    <BrowserRouter>
        <div className="Main" align="center">
            <div className="width-limit">
                <div className="">
                    {/* Top */}
                    <Header/>
                    {/* Middle */}
                    <Route exact path='/' component={Home} />
                    <Route exact path='/massandconfession' component={Mass_Confession} />
                    <Route exact path='/bulletin' component={Bulletin} />
                    <Route exact path='/parishgroups' component={Parish_Groups} />
                    <Route exact path='/sacraments' component={Sacraments} />
                    <Route exact path='/prep' component={PREP} />
                    <Route exact path='/rcia' component={RCIA} />
                    <Route exact path='/donate' component={Donate} />
                    <Route exact path='/contact' component={Contact} />
                    <Route exact path='/calendar' component={Calendar} />
                    <Route exact path='/tnc' component={TNC} />

                    <Route exact path='/parishgroups/altarserving' component={Altar_Serving} />
                    <Route exact path='/parishgroups/biblestudygroup' component={Bible_Study_Group} />
                    <Route exact path='/parishgroups/bingo' component={Bingo} />
                    <Route exact path='/parishgroups/cwl' component={CWL} />
                    <Route exact path='/parishgroups/goldenbees' component={Golden_Bees} />
                    <Route exact path='/parishgroups/knightofcolumbus' component={Knight_of_Columbus} />
                    <Route exact path='/parishgroups/legionofmary' component={Legion_of_Mary} />
                    <Route exact path='/parishgroups/liturgyoftheword' component={Liturgy_of_the_word} />
                    <Route exact path='/parishgroups/prayergroup' component={Prayer_Group} />
                    <Route exact path='/parishgroups/sosvdp' component={SOSVDP} />
                    <Route exact path='/parishgroups/ym' component={YM} />
                    {/* Bottom */}
                    <Footer/>
                </div>
            </div>
        </div>
    </BrowserRouter>
, document.getElementById('root'));
//ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
