import React from 'react';
import $ from 'jquery';
import 'filepond/dist/filepond.min.css';
import Methods from '../Bulletin/Methods.php';

function compare(a, b) {
  var a_tmp = a.split('_');
  var b_tmp = b.split('_');
  var a_month = parseInt(a_tmp[0]);
  var b_month = parseInt(b_tmp[0]);
  if (a_month == b_month) {
    var a_int = parseInt(a_tmp[2]);
    var b_int = parseInt(b_tmp[2]);
    return b_int - a_int;
  } else {
    return 0;
  }
}

function doSomething() {
  var returned_result = ['Hello'];
  $.ajax({
    async: false,
    type: 'POST',
    url: Methods,
    data: "function=getFileNames",
    success: function (result)
    {
      //console.log(result);
      returned_result = JSON.parse(result);
    }
  });

  var sorted_list = [];
  var special_list = [];
 
  returned_result.forEach(function(element) {
    var special = element.split('_');
    if (parseInt(special[0]) != 0) {
      sorted_list.push(element);
    } else {
      special_list.push(element);
    }
  });

  sorted_list.sort(compare);
  var new_list = special_list.concat(sorted_list);

  var displayed_value = [];
  new_list.forEach(function(element) {
    var str = element.split('_');
    if (parseInt(str[0]) == 0) { // Aka. for example 0_Christmas_2019.pdf
      ///public_html/static/media/bulletin/
	  ///static/media/bulletin/
	  var endingStr2 = str[2].split('.');
      displayed_value.push(<div><a href={"/static/media/bulletin/" + element} target="_blank" rel="noopener noreferrer">{str[1].toUpperCase() + " " + endingStr2[0]}</a></div>);

	  displayed_value.push(<br/>);
    } else { // Aka. for example 1_JAN_19_2019.pdf
      var endingStr3 = str[3].split('.');
      displayed_value.push(<div><a href={"/static/media/bulletin/" + element} target="_blank" rel="noopener noreferrer">{str[1].toUpperCase() + " " + str[2] + " " + endingStr3[0]}</a></div>);
      displayed_value.push(<br/>);
    }
  });

  return displayed_value;
}

function Bulletin() {
  return (
    <div className="Bulletin custom-container">
        <div className="row">
            <div className="col-md-4">
					Did you know you can receive<br/>
					our bulletin directly to your inbox?<br/>
					Sign up by clicking <a href="https://stbernadette.catholicvan.com/newsletters/subscribers/new"><b>HERE...</b></a><br/>
                <h5>Browse Bulletins by Date</h5>
				<br/>
                {/* <img className="image-border title-image" src={require('../../assets/titleimage.jpg')}/> */}
                {/* <a ref={require("../../assets/prep/PREPreg2019.pdf")}></a> */}
                {/* <a href={prepPDF} target="_blank" >pdf file</a> */}
                {doSomething()}
            </div>
            <div className="col-md-8">
                <h5>Saint Bernadette Parish Calendar</h5>
                <a href="https://calendar.google.com/calendar/embed?src=parish%40stbernadette.ca"><p><b>Click Here for full view</b></p></a>
                <div className="responsiveCal">
				<iframe title="calendar" src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;src=parish%40stbernadette.ca&amp;color=%23D81B60&amp;mode=AGENDA" width="100%" height="900" frameBorder="0" scrolling="no"></iframe>
				</div>
				<hr/>
				<h5>Announcements</h5>
                <p>To place your <b>announcement</b> of your upcoming Parish Activity in the Parish Bulletin 
				please <a href="/contact">contact</a> the Parish Office at <b>604.596.0566</b></p>
			</div>
       </div>
    </div>
  );
}

export default Bulletin;