import React from 'react';

function Altar_Serving() {
  return (
    <div className="custom-container">
        <h5>
            ALTAR SERVING AT ST. BERNADETTE PARISH
        </h5>
        <p className="align-left">
        <b>Mission</b><br/>
        All altar server activities are in support of the following goals for the young men and women who actively participate in the altar server ministry. 
        The altar server mission is: <br/>
        - To develop a closer relationship with the Lord,<br/>
        - To grow in the knowledge of the teachings of the Catholic Church,<br/>
        - To serve the parish by assisting at Mass and other religious services.<br/>
        <br/>
        <b>Requirements</b><br/>
        Altar servers at St. Bernadette Parish are young men and women who are Catholic, have been Baptized, and have received their First Holy Communion. They must also have attended a training session, generally held in October each year. The training session will help to clarify some church history and symbolism, explaining why we do what we do during mass, and will allow time for questions.<br/>
        <br/>
        <b>Expectations</b><br/>
        In order to serve at Mass, altar servers are expected to: <br/>
        • Maintain reverence during Mass and other liturgical services. In the House of the Lord, reverence means maintaining a prayerful attitude at all times and includes: <br/>
        • Folding hands in prayer when standing, walking or kneeling. The only exceptions are when sitting or holding an object. <br/>
        • Participating in all prayers and songs. Remember, singing is praying twice. <br/>
        • Sitting straight with both feet on the floor (no crossed legs, no slouching). <br/>
        • Facing the person talking.<br/>
        • Paying attention. Since it is easy to be distracted in church, it requires effort to pay attention and to pray. <br/>
        • Handling all liturgical items reverently, prayerfully and carefully. <br/>
        • Participate in all altar server training sessions and meetings. <br/>
        • Arrive at least 15 minutes before assigned services. <br/>
        • Call other altar servers to find a replacement when ill or not able to serve.<br/>
        • Work as a team with other altar servers and coordinators to serve the ministers and parishioners in the best manner possible. <br/>
        • Help to educate and train new altar servers in a loving and Christ-like manner. <br/>
        • Be a good example to others in humility, dress, reverence, words and actions.<br/>
        <br/>
        <b>Dress and Appearance</b><br/>
        Altar servers wear robes that present a very reverent and clean look. Since these robes often do not cover an altar server's shoes, it is important that the shoes servers wear are in keeping with the robes they wear. For this reason, servers should wear black closed toe shoes. Please, no tennis shoes or flip-flops.<br/>
        <br/>
        <b>"No-Show" Altar Servers</b><br/>
        From time to time an altar server doesn't show for an assigned Mass. This is not desirable but does happen. For this reason, servers who are not scheduled to serve and arrive early for Mass are encouraged to stop by the Altar Servers room in case a fill-in is needed.<br/>
        <br/>
        <b>Contact</b><br/>
        If you are interested in this ministry, please contact <b>Father Paul Bui</b> at <b>604-596-0566</b>.
        </p>
    </div>
  );
}

export default Altar_Serving;