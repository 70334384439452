import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap';
import {Link} from 'react-router-dom';

class Header extends React.Component {
	componentDidMount() {
		document.title = "St. Bernadette Parish";
	}
	
	render() {
		return (
			<div className="Header" align="left">
			  <div className="align-center">
				  <h1>
					  Saint Bernadette Parish
				  </h1>
				  <h5>
					  Roman Catholic Church - Surrey BC
				  </h5>
			  </div>
			  <div className=''>
				<nav className="navbar navbar-expand-lg navbar-dark bg-dark">
					<Link to="/" className="navbar-brand">
					  <span data-toggle="collapse" data-target=".navbar-collapse.show">Home</span>
					</Link>
					<button className="navbar-toggler" type="button" data-toggle="collapse"  data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>

					<div className="collapse navbar-collapse" id="navbarNavAltMarkup">
						<div className="navbar-nav">
							<Link to="/massandconfession" className="nav-item nav-link">
							  <span data-toggle="collapse" data-target=".navbar-collapse.show">Mass & Confession</span>
							</Link>
							<Link to="/bulletin" className="nav-item nav-link">
							  <span data-toggle="collapse" data-target=".navbar-collapse.show">Bulletin</span>
							</Link>
							<Link to="/parishgroups" className="nav-item nav-link">
							  <span data-toggle="collapse" data-target=".navbar-collapse.show">Parish Groups</span>
							</Link>
							<Link to="/sacraments" className="nav-item nav-link">
							  <span data-toggle="collapse" data-target=".navbar-collapse.show">Sacraments</span>
							</Link>
							<Link to="/prep" className="nav-item nav-link">
							  <span data-toggle="collapse" data-target=".navbar-collapse.show">PREP</span>
							</Link>
							<Link to="/rcia" className="nav-item nav-link">
							<span data-toggle="collapse" data-target=".navbar-collapse.show">RCIA</span>
							</Link>
							{/*<Link to="/donate" className="nav-item nav-link">*/}
							<a href="/donate" className="nav-item nav-link">
							<span data-toggle="collapse" data-target=".navbar-collapse.show">Donate</span>
							</a>
							{/*</Link>*/}
							<Link to="/calendar" className="nav-item nav-link">
							<span data-toggle="collapse" data-target=".navbar-collapse.show">Calendar</span>
							</Link>
							<Link to="/contact" className="nav-item nav-link">
							<span data-toggle="collapse" data-target=".navbar-collapse.show">Contact</span>
							</Link>
							{/* <a className="nav-item nav-link disabled" href="localhost:3000" tabindex="-1" aria-disabled="true">Disabled</a> */}
						</div>
					</div>
				</nav>
			  </div>
			</div>
		);
	}
  
}

export default Header;