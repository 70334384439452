import React from 'react';
import '../../styles/Golden_Bees.css'

function Golden_Bees() {
  return (
    <div className="custom-container">
        <h5>GOLDEN BEES</h5><br/>
        A warm welcome to all of our parishioners, 55 years and better, to join us every 2nd Thursday of the month at 12:00 noon for our POTLUCK LUNCH and social gathering. 
        There are no fees. Singles or couples are asked to bring their favorite main dish or salad or dessert to share. We have enjoyed a variety of foods and always have a good mix to eat. 
        <br/>
        Please give it a try. We need more members.
        <br/><br/>
        <div className="image-container">
            <img alt="potluck" className="image-border potluck-image" src={require('../../assets/potluck.png')}/>
        </div>
        <br/>
        We usually play card games that anyone can play after our meal. We are also open to new ideas! The more the merrier!<br/><br/>
        <div className="image-container">
            <img alt="gathering" className="image-border gathering-image" src={require('../../assets/gathering.png')}/>
        </div>
        
        <br/>
        See you at the next potluck on in the Parish Centre! <br/>
        Any questions, please call <b>Lee</b> at <b>604-582-9208</b>.
    </div>
  );
}

export default Golden_Bees;