import React from 'react';
import '../../styles/Calendar.css'

function Calendar() {
  return (
    <div className="Contact custom-container">
      <div>
        <h5>St Bernadette Parish Calendar</h5>
        <p><b><a href="https://calendar.google.com/calendar/embed?src=parish%40stbernadette.ca">Click Here for full view</a></b></p>
        <div className="responsiveCal">
		<iframe title="image" src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;src=parish%40stbernadette.ca&amp;color=%23D81B60&amp;mode=AGENDA" width="100%" height="1200px" frameBorder="0px" scrolling="no"></iframe>
        </div>
	  </div>
    </div>
  );
}

export default Calendar;