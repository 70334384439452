import React from 'react';
import '../../styles/YM.css'

function YM() {
  return (
    <div className="custom-container">
        <h5>ST. BERNADETTE PARISH YOUTH & YOUNG ADULT MINISTRY</h5><br/>
        <div className="image-container">
            <img alt="youth and young adult ministry" className="image-border ym-image" src={require('../../assets/youthandyoungadultministry.png')}/>
        </div>
        <p><br/>
        For information on upcoming events or information about our youth ministry,<br/>
        please visit our Facebook page at 
        </p>
        <b><a href="https://www.facebook.com/StBernadetteYYAM/">St Bernadette's Youth Ministry on FACEBOOK...</a></b>
        <br/><br/>
        <b><a href="https://www.instagram.com/stbernadetteyyam/">St Bernadette's Youth Ministry on INSTAGRAM...</a></b>
        <br/><br/>
        or contact the parish office
    </div>
  );
}

export default YM;