import React from 'react';
import '../../styles/Home.css'

function Home() {
  return (
    <div className="Home custom-container">
        <div className="row">
            <div className="col-md-7">
				<p>
					    Welcome!  Thank you for visiting our website.
                </p>
                <div className="image-container">
                    <img alt="titleImage" className="image-border title-image" src={require('../../assets/titleimage.jpg')} />
                </div>
                <hr/>
				<p>
						Please remember that if you have any COVID symptoms<br/>
						or are feeling unwell you should NOT attend Mass.
						Masks are no longer required in public indoor settings 
						under a public health order, as of March 11, 2022, 
						but we remain kind and understanding to those who wish 
						to continue to use this layer of protection.  
						We are also back to full capacity in the church.
						<hr/>
						Weekend Masses: Saturday 5pm <br/>Sunday 8:30am, 10am, 11:30am<br/><br/>
						Weekday Masses:  9:10am Monday to Friday <br/>9:00am Saturday<br/>
						(Note: Mon-Fri Mass times are at 9:00am when school is not in session,<br/>
						spring break, summer, Christmas breaks)<br/><br/>
						<a href="https://www.facebook.com/StBernadetteChurchSurreyFacebook"><b>Facebook</b> @StBernadetteChurchSurrey</a><br/> 
						<a href="https://www.instagram.com/stbernadette_church/"><b>Instagram</b> stbernadette_church</a><br/>
						<a href="https://twitter.com/StBeechurch"><b>Twitter</b> @StBeechurch</a>
						<br/><br/>
						If you have additional questions or concerns,<br/>
						please feel free to reach out to the parish office.
				</p>
			</div>
            <div className="col-md-5">
                <p>
                    <b>Pastor Fr. Paul Than Bui</b><br/>
                </p>
                <hr/>
                <h4>
                    <b>St Bernadette Parish</b>
                </h4>
				<br/>
                <div className="image-container cross-image">
                    <img alt="small_cross" className="image-border" src={require('../../assets/smallcross.png')}/>
                </div>
				<br/>
                <p>
                    <b>6543 132nd Street Surrey, BC V3W 4L4 <br/>
                    Tel: 604.596.0566<br/>
                    Email: parish@stbernadette.ca
					</b>
				</p>
				<hr/>
				<h5><b><a href="https://calendar.google.com/calendar/embed?src=parish%40stbernadette.ca">St Bernadette Parish Calendar</a></b></h5>
                <hr/>
                <h5><b><a href="http://www.stbernadetteschool.ca/">St Bernadette Elementary School</a></b></h5>
                <div className="image-container">
                    <img alt="smallSchoolLogo" className="image-border school-image" src={require('../../assets/SmallSchoolLogo.png')}/>
                </div><br/>
                <p css="font: 10px;">
                    13130 65B Ave Surrey, BC V3W 9M1 <br/>
                    Tel: 604.596.1101<br/>
                    Fax: 604.596.1550<br/>
                    Email: admin@stbernadette.ca
                </p>
                
				<p>
				</p>
        </div>
		</div>
			<div id="donation">
		</div>
    </div>
  );
}

export default Home;
