import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap';

function Footer() {
  return (
    <div className="footer-style align-center">
        <hr/>
        <div>
            <a href="/">Home</a><span className="dot"/>
            <a href="/massandconfession">Mass & Conf</a><span className="dot"/>
            <a href="/bulletin">Bulletin</a><span className="dot"/>
            <a href="/parishgroups">Parish Groups</a><span className="dot"/>
            <a href="/sacraments">Sacraments</a><span className="dot"/>
            <a href="/prep">PREP</a><span className="dot"/>
            <a href="/rcia">RCIA</a><span className="dot"/>
            <a href="/donate">DONATE</a><span className="dot"/>
            <a href="/calendar">Calendar</a><span className="dot"/>
            <a href="/contact">Contact</a>
        </div>
        <div>
			<a href="/tnc">"Terms & Conditions"</a><br/>
            Created by <a href="http://allsystems.ca/">allsystems.ca</a><br/><br/><br/>
			
        </div>
    </div>
  );
}

export default Footer;