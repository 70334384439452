import React from 'react';
import '../../styles/TNS.css'

function SOSVDP() {
  return (
    <div className="custom-container">
        <h5>SOCIETY OF ST. VINCENT DE PAUL</h5>
        <p>
        <b>St. Bernadette Conference - founded August 1st, 2000</b>
        </p>
        <p className="align-left">
        The Society of St. Vincent de Paul is an international Catholic organization of lay people founded in 1833 by Blessed Frederic Ozanam and his companions.  It is inspired by our patron saint, St. Vincent de Paul.
        <br/><br/>
        The Society’s mission is to live the Gospel message by serving Christ in the poor with love, respect, justice and joy.  As Vincentiens, our values are to see Christ in anyone who suffers; come together as a family; have personal contact with the poor; and help in all possible ways.
        <br/>
        </p>
        <b>What do we do?</b>
        <p className="align-left">
        <br/>
        <b>Emergency food hampers</b> are supplied weekly to any person(s) or family when needed.  Messages requesting assistance can be left on our messaging line at 604-785-4334.
        <br/><br/>
        <b>Street ministry teams</b> are active each and every Sunday, supplying approximately 60 lunch bags per week.  As well, at certain times of the year, like Christmas we distribute gifts such as sleeping bags, back packs, toques, gloves and sock.  In 2017, over 5000 lunch bags were given out.
        <br/><br/>
        <b>Furniture Due</b> to the increasing cost of storage, we no longer collect or distribute furniture.
        <br/><br/>
        <b>Visitation</b> programs allow two of our members to visit the sick, elderly and disabled individuals.
        <br/><br/>
        <b>Focused Family Assistance</b> is a program in which we select specific families, who are usually immigrant or refugee families, and who are in dire need of help, and whom we feel are well motivated to improve their well-being.  They are supplied with food as needed, as well as vouchers to purchase other necessary items for employment, bus tickets/passes.  Sometimes funding is required for deposits for accommodation.
        <br/><br/>
        <b>Twinning</b> is a program in which we arrange to twin with another conference in the world, and we assist them with funding on a quarterly basis and communicate with several times per year.  In 2014, we arranged to twin with a conference in Haiti.  We are also hoping to fund a work project in the future.
        <br/><br/>
        <b>Miscellaneous</b> assistance that we have been involved in includes financially assisting foreign conferences involved in local disaster relief.  We have also assisted individuals to attend appointments with their physician or pharmacist.
        <br/><br/>
        <b>How do we do this?</b><br/>
        - funds raised by selling vegetables after all weekend masses from May through November.<br/>
        - direct parishioner financial donations.<br/>
        - parishioner donations of furniture, clothing or other items as needed.<br/>
        - special collection (green) envelopes provided in the box of yearly donation envelopes.<br/>
        - Knights of Columbus financial assistance.<br/>
        - St. Bernadette Elementary School food drives.<br/>
        - members weekly voluntary donations.<br/>
        <br/>
        To all who have supported us in any way, whether by direct financial contribution, donation of food, clothing or other items, and by supporting our after-mass vegetable sales, we offer you our sincere thanks!
        <br/><br/>
        If you are interested in learning more about the work of the SSVP or how you may be able to help, or if you know someone who may require a visit or our assistance, please:
        <br/>
        - speak to one of the members in church<br/>
        - come to one of our meetings, held monthly on the third Monday at 7pm in room D of the Parish Centre<br/>
        - call the St. Bernadette Parish office at 604-596-0566, requesting one of us to call back<br/>
        - contact us directly by leaving a message at <b>604-785-4334</b><br/>
        or email <b>ssvp.stbernadette@gmail.com</b>
        </p>
    </div>
  );
}

export default SOSVDP;