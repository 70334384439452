import React from 'react';

function Donate() {
  return (

    <div className="Donate custom-container">
        <div className="row">
            <div className="col-md-12">
              <p>Thank you for your generous support!
			  <br/><br/><br/>

			<b>MY SUNDAY OFFERING</b><br/>
			It is now possible to make your regular offertory gift online, either by credit card or direct bank deposit.<br/>
			Your parish relies on your regular offertory giving to both sustain itself and grow.<br/>
			By making your pre-authorized offertory gift, you are providing crucial support to carry your parish during this difficult time.<br/>
			<br/>
			<b>RECURRING PAYMENT (weekly or monthly)</b><br/>
			<b><a href="https://support.rcav.org/donate-sunday-offering">Sunday Offering Donation...</a></b><br/>
			<br/>
			<b>SECOND COLLECTIONS</b><br/>
			<b><a href="https://support.rcav.org/parishes/second-collections">Second Collections Donation...</a></b><br/><br/>
			<b>ONE TIME DONATION</b><br/>
			<b><a href="https://support.rcav.org/donate-one-time">One Time Donation...</a></b><br/><br/>
			If you have questions regarding your donation or would like to make changes to your gift please contact<br/>
			the Archdiocese of Vancouver Development Office at 604-683-0281 ext #324<br/>
			<br/>
			This transaction is facilitated by the Archdiocese of Vancouver on behalf of St. Bernadette Parish.<br/>
			If you have questions regarding this form please contact the Development Office at 604-683-0281.
			</p></div>
		</div>
    </div>
  );
}

export default Donate;