import React from 'react';
import prepPDF from "../../assets/prep/prepPDF.pdf"

function PREP() {
  return (
    <div className="PREP custom-container">
        <div className="row">
            <div className="col-md-5">
                <h5>PREP</h5>
                <p>(Parish Religious Education Program)</p>
				Registration is now open for the fall of 2024,<br/>
				see the link below...<br/><br/>
                <p className="align-left">Classes for children from <b>Kindergarten to Grade 7</b>,
				who are attending public school, homeschool or are needing some catchup faith education.
				Classes are held on Wednesday evenings at 6:30pm.<br/>
				<b>PREP is an 8-year Religious Education Program.</b><br/><br/>
				<b>Families are encouraged to register their children<br/>
				as they enter Kindergarten</b>, however, we can help parents
				find solutions for children who have not received prior religious education,
				children returning after an absence from PREP, and youth ages 12-17
				who have not yet received the Sacraments.</p>
				<br/>
                <b>Our PREP classes run from October to May each year.</b>  Please register early as classes do fill up.<br/>
                <p className="align-left">It is recommended that the preparation and celebration of the Sacraments of First Holy Communion and 
				Confirmation have a minimum of two years instruction.</p>
                <hr/>
                Coordinator: <b>Lisa</b><br/>
                Telephone: <b>604.596.1833</b><br/>
                Email: <b>prep@stbernadette.ca</b>
                <hr/>
				<b>PREP</b> for our 2024-2025 classes will begin on<br/>
				October 2 for the Fall 2024 school year.<br/>
				Pre-registration is required, please see our<br/>
				registration form via the link.<br/>
				<b><a href={prepPDF}>PREP Registration Form 2023-2024 (PDF)</a></b>
				
            </div>
            <div className="col-md-7">
                <h5>PREP Calendar</h5>
                <a href="https://calendar.google.com/calendar/embed?src=prep%40stbernadette.ca"><p><b>Click Here for full view</b></p></a>
                <div className="responsiveCal">
				<iframe title="calendar" src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;src=prep%40stbernadette.ca&amp;color=%23D81B60&amp;mode=AGENDA" width="100%" height="600px" frameBorder="0px" scrolling="no"></iframe>
				</div>
			</div>
       </div>
    </div>
  );
}

export default PREP;