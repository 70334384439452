import React from 'react';
import '../../styles/TNS.css'

function TNC() {
  return (
    <div className="custom-container">
      <b>Terms and Conditions:</b>
      <hr/>
        We reserve the right to deny, withdraw or amend an announcement that we provide in our bulletin or on the website without notice. We reserve the right to alter or remove the whole or any part of an announcement at any time and without notice. We make no warranties as to the availability, accessibility or typographic errors that may occur. We will not be held liable for any damages, loss, cost or expenses incurred as a result of or any lack thereof as of the availability, accessibility or typographic errors of the website at any time or for whatever reason.
    </div>
  );
}

export default TNC;