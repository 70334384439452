import React from 'react';
import '../../styles/Knight_of_Columbus.css'

function Knight_of_Columbus() {
  return (
    <div className="custom-container">
        <h5>KNIGHTS OF COLUMBUS JOSEPH KRENN COUNCIL #8629</h5><br/>
        <div className="image-container">
            <img alt="knights of columbus" className="image-border knight-image" src={require('../../assets/img9.jpg')}/>
        </div>
        <br/>
        <p>
        Our <b>council meets</b> on the <b>first Thursday</b> of <b>each month</b> at <b>7:30 p.m.</b>
        in <b>Room A</b> of the <b>St. Bernadette Parish Centre.</b><br/>
        For more information, please contact us at <b>778-892-3000</b>
        <br/><br/>
        <b>All the good works we do are informed by our four core principles:</b>
        </p>
        <p className="align-left">
        <b>Charity</b> – Our Catholic faith teaches us to “Love thy neighbor as thyself.” Members of the Knights of Columbus show love for their neighbors by conducting food drives and donating the food to local soup kitchens and food pantries, by volunteering at Special Olympics, and by supporting, both spiritually and materially, mothers who choose life for their babies. Knights recognize that our mission, and our faith in God, compels us to action. There is no better way to experience love and compassion than by helping those in need, a call we answer every day.
        <br/><br/>
        <b>Unity</b> – None of us is as good as all of us. Members of the Knights of Columbus all know that – together – we can accomplish far more than any of us could individually. So we stick together…we support one another. That doesn’t mean that we always agree or that there is never a difference of opinion. It does mean that – as a Knight of Columbus – you can count on the support and encouragement of your brother Knights as you work to make life better in your parish and community.
        <br/><br/>
        <b>Fraternity</b> – The Venerable Michael J. McGivney founded the Knights of Columbus, in large part, to provide assistance to the widows and children left behind when the family breadwinner died – often prematurely. The Order’s top-rated insurance program continues to do this today, as do individual Knights, who last year gave more than 10 million hours of their time to assist sick and/or disabled members and their families. In the Knights of Columbus, we watch out for and take care of one another.
        <br/><br/>
        <b>Patriotism</b> – Members of the Knights of Columbus, be they Americans, Canadians, Mexicans, Cubans, Filipinos, Poles, or Dominicans, are patriotic citizens. We are proud of our devotion to God and country, and believe in standing up for both. Whether it’s in public or private, the Knights remind the world that Catholics support their nations and are amongst the greatest citizens.
        </p>
        <p>
        <b>Links of interest to members and visitors:</b><br/>
        Our webpage <a href="http://kofc8629.ca">http://kofc8629.ca</a><br/>
        British Columbia & Yukon State Council <a href="https://kofcbc.org">www.kofcbc.org</a><br/>
        Archdiocese of Vancouver <a href="https://rcav.org">www.rcav.org</a><br/>
        </p>
    </div>
  );
}

export default Knight_of_Columbus;