import React from 'react';
import '../../styles/Contact.css'

function Contact() {
  return (
    <div className="Contact custom-container">
      <div className="row">
        <div className="col-md-5">
          <h5>Parish Contact Information</h5>
          <p>
            <b>St Bernadette Parish</b><br/>
            6543 - 132nd Street Surrey BC V3W 4L4
          </p>
          <p>
            Tel: <b>604.596.0566</b> <br/>
            Fax: 604.597.9534
          </p>
          <p>
          Email: <a href="mailto:parish@stbernadette.ca">parish@stbernadette.ca</a><br/>
          </p>
          <h5>Office Hours</h5>
          <table className="table align-center">
			  <tbody>
				<tr>
				  <td>Monday</td>
				  <td>8:30am – 4:00pm</td>
				</tr>
				<tr>
				  <td>Tuesday</td>
				  <td>8:30am – 4:00pm</td>
				</tr>
				<tr>
				  <td>Wednesday</td>
				  <td>8:30am – 4:00pm</td>
				</tr>
				<tr>
				  <td>Thursday</td>
				  <td>8:30am – 4:00pm</td>
				</tr>
				<tr>
				  <td>Friday</td>
				  <td>8:30am – 4:00pm</td>
				</tr>
				<tr>
				  <td colSpan="2">(closed for lunch, 12:00pm - 1:00pm)</td>
				</tr>
				<tr>
				  <td colSpan="2">(closed Saturday, Sunday & Holidays)</td>
				</tr>
			</tbody>
          </table>
        </div>
		<div className="col-md-7">
          <h5>Google Location Map</h5>
          <iframe title="google maps" width="100%" height="500px" id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2611.1652427507024!2d-122.859805!3d49.1214968!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5485dbc64b99ae49%3A0xaf9ddbb9c5e66714!2sSaint%20Bernadette%20Parish!5e0!3m2!1sen!2sca!4v1681588365798!5m2!1sen!2sca" frameBorder="0px" scrolling="no" marginHeight="0px" marginWidth="0px" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
          </iframe>
        </div>
      </div>
    </div>
  );
}

export default Contact;