import React from 'react';
import '../../styles/TNS.css'

function Prayer_Group() {
  return (
    <div className="custom-container">
        <h5>PRAYER GROUP</h5><br/>
        <b>Joy of the Lord</b><br/><br/>
        We meet every <b>Monday</b> at <b>7:00pm</b> in <b>Room A</b> of the St. Bernadette Parish Centre.<br/>
        Join us!<br/><br/>
        If you would like more information, please contact <b>Danny</b> at <b>604-596-2097.</b>
    </div>
  );
}

export default Prayer_Group;