import React from 'react';

function Bible_Study_Group() {
  return (
    <div className="custom-container">
        <h5>BIBLE STUDY GROUP</h5><br/>
        <p>
        <b>Fruit of the Vine</b><br/><br/>
        We meet every Thursday at 7:00pm in Room C of the St. Bernadette Parish Centre. 
        Join us!<br/>
        <br/>
        If you would like more information, please contact Phil at <b>604-596-6389</b>.
        </p>
    </div>
  );
}

export default Bible_Study_Group;