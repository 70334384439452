import React from 'react';
import '../../styles/Bingo.css'

function Bingo() {
  return (
    <div className="custom-container">
        <div className="image-container">
            <img alt="bingo" className="image-border bingo-image" src={require('../../assets/bingo.png')}/>
        </div><br/>
        <h5><a href="https://www.facebook.com/St-Bernadette-Friendly-Bingo-381782399282877/?__tn__=%2Cd%2CP-R&eid=ARDEx0VuoATto2lqTY3yKPerbu2LqvU2o9nmikd5tiE9wNUWjG51nWZ5bd1W-TNhfehXW-zRu1YxXZTk">St. Bernadette Friendly BINGO (on FACEBOOK)</a></h5>
        <br/>
        <p className="align-left">
        Our Bingo takes place every week on Tuesday evenings. We play 18 regular games and 10 special games. Regular games begin at 6:30pm.
        <br/><br/>
        Progressive jackpot: watch for updates on the sign at the entrance to our parking lot to find out the jackpot total and number of calls required.
        <br/><br/>
        If you are interested in volunteering at Bingo please see the information below or 
        contact Erin, our Bingo Manager at <b>bingo@stbernadette.ca</b> or call <b>604-596-1833.</b>
        <br/><br/>
        In order to continue providing a successful social activity to our community, we need more volunteers. With so many bingo halls closing, we are seeing an increase in patrons, which positively increases the revenue to our parish. In the past, our bingo has provided an annual equivilant in revenue to three major fundraisers. We would very much like to keep our Bingo open and continue to serve our community! Below is detailed information on what volunteering at bingo looks like. Please consider giving back to your parish and your community by volunteering at Bingo!
        <br/><br/>
        <b>Office Assistant:</b> 4:30-10:30pm<br/>
        Sell dabbers, input data into spreadsheet, handle cash register payouts and float setup
        <br/><br/>
        <b>Cashier:</b> (2 per night) 4:45-7:15pm<br/>
        Sell regular game cards and Lucky 7s, keep a running total of players
        <br/><br/>
        <b>Special Game Sellers:</b> 5:00-9:30pm<br/>
        Sell Bingo game tickets on the floor and manage a float (some games stop selling earlier, so shift may end earlier)
        <br/><br/>
        <b>Callers:</b> (shared shift between 2 people) 5:45-10:30pm<br/>
        Call the numbers for Bingo games, sell Chicken Feed games on the floor, fill in the callers' tally sheet
        <br/><br/>
        <b>Floor Captain:</b> 5:50-10:30pm<br/>
        Responsible for distributing Lucky Bucks, report to office the number of winners & Lucky Bucks awarded per game, distribute prize money to floor workers, distribute and collect Lucky Bucks slips, complete and balance all floor workers tally sheets
        <br/><br/>
        <b>Floor Workers:</b> 6:30-10:15pm<br/>
        Report and record winning card numbers to callers, pay out prize money, remove used cards and garbage from tables throughtout the night, end of night clear tables, bag all paper and garbage and wash table surfaces
        <br/><br/>
        <b>Concession Cook:</b> 2:00-6:00pm<br/>
        Set up and preparation of all cooked items (burgers and hot dogs)
        <br/><br/>
        <b>Concession Workers:</b> 5:30-9:00pm<br/>
        Collect cash, sell concession items, end of night kitchen cleanup, inventory control
        <br/><br/>
        <b>We welcome all visitors!!</b>
        <br/><br/>
        All are welcome to stop by any Tuesday night to observe Bingo in action and see if there is a position for you! Our volunteers are very valuable to our parish, our school, and our community, and we thank you in advance for your help and stewardship! 
        </p>
    </div>
  );
}

export default Bingo;