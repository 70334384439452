import React from 'react';
import '../../styles/Sacraments.css'

function Sacraments() {
  return (
    <div className="Sacraments custom-container">
        <div className="row">
            <div className="col-md-7">
                <h5>Sacraments</h5>
                <h6>Baptism</h6>
                <p className="align-left">
                  Parents of children to be baptized must be registered in the Parish 
				  and must make themselves available for instructions regarding the rights, 
				  privileges, duties and responsibilities assumed in the reception of this Sacrament (Baptism Prep Class).
				  Please contact the parish office to begin the process and to schedule your child’s baptism.
                </p>
                <h6>Marriage</h6>
                <p className="align-left">
                  Couples planning on getting married at St. Bernadette Parish should give at least four months notice before the proposed date. One of the parties to the proposed marriage should be a practicing Catholic. Couples are expected to take the full Marriage Preparation Course and are asked to obtain a NEW Baptismal Certificate.<br/>
				  <a href="https://rcav.org/marriage-and-family">https://rcav.org/marriage-and-family</a><br/><br/>
                </p>
                <h6>Sick Calls - As Requested</h6>
                <p>Please phone the Parish Office at <b>604.596.0566</b></p>
            </div>
            <div className="col-md-5">
              <img alt="simage" className="image-border s-image" src={require('../../assets/simage.jpg')}/>
            </div>
       </div>
    </div>
  );
}

export default Sacraments;