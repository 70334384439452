import React from 'react';
import '../../styles/Parish_Groups.css'
import zumbapdf from "../../assets/zumba.pdf"
import ecpdf from "../../assets/ec.pdf"

function Parish_Groups() {
  return (
    <div className="Parish_Groups custom-container">
        <div className="row">
            <div className="col-md-5">
                <h5>St Bernadette Parish Groups</h5>
                <table className="table">
					<tbody>
					  <tr>
						<td><a href="/parishgroups/altarserving"><b>Altar Servers</b> <br/>(read more)</a></td>
						<td>604.599.4206</td>
					  </tr>
					  <tr>
						<td><a href="/parishgroups/biblestudygroup"><b>Bible Study Group</b><br/>(read more)</a></td>
						<td>604.596.6389</td>
					  </tr>
					  <tr>
						<td><a href="/parishgroups/bingo"><b>BINGO</b></a></td>
						<td>604.596.1833</td>
					  </tr>
					  <tr>
						<td colSpan="2"><a href="https://www.facebook.com/St-Bernadette-Friendly-Bingo-381782399282877/?__tn__=%2Cd%2CP-R&eid=ARDEx0VuoATto2lqTY3yKPerbu2LqvU2o9nmikd5tiE9wNUWjG51nWZ5bd1W-TNhfehXW-zRu1YxXZTk"><b>BINGO's FACEBOOK PAGE...</b></a></td>
					  </tr>
					  <tr>
						<td><a href="/parishgroups/liturgyoftheword"><b>Children's Liturgy</b><br/>(read more)</a></td>
						<td>604.596.0566</td>
					  </tr>
					  <tr>
						<td><b>Couples for Christ</b></td>
						<td>604-783-0577</td>
					  </tr>
					  <tr>
						<td><a href="/parishgroups/cwl"><b>CWL</b></a></td>
						<td>778-238-0525</td>
					  </tr>
					  <tr>
						<td><a href={ecpdf}><b>Education Committees (PDF)</b></a></td>
					  </tr>
					  <tr>
						<td><a href="/parishgroups/knightofcolumbus"><b>Knights of Columbus</b><br/>(read more)</a></td>
						<td>778-892-3000</td>
					  </tr>
					  <tr>
						<td><a href="/parishgroups/legionofmary"><b>Legion of Mary</b><br/>(read more)</a></td>
						<td>604-551-0871 or <br/>778-628-2084</td>
					  </tr>
					  <tr>
						<td><b>Line Dancing</b></td>
						<td>604.534.4273</td>
					  </tr>
					  <tr>
						<td><a href="/parishgroups/prayergroup"><b>Prayer Group</b><br/>(read more)</a></td>
						<td>604.596.2097</td>
					  </tr>
					  <tr>
						<td><a href="/prep"><b>PREP</b></a></td>
						<td>604.596.1833</td>
					  </tr>
					  <tr>
						<td><a href="http://rcav.org/prison-and-justice-services/"><b>Prison Ministry</b><br/>(read more)</a></td>
						<td>604.588.7031</td>
					  </tr>
					  <tr>
						<td><a href="/rcia"><b>RCIA</b><br/>(read more)</a></td>
						<td>604.837.8021</td>
					  </tr>
					  <tr>
						<td><a href="/parishgroups/sosvdp"><b>St Vincent de Paul</b><br/>(read more)</a></td>
						<td>604.785.4334</td>
					  </tr>
					  <tr>
						<td><a href="/parishgroups/ym"><b>Youth Ministry</b></a></td>
						<td>778-839-2104</td>
					  </tr>
					  <tr>
						<td><a href={zumbapdf}><b>ZUMBA</b></a></td>
						<td>778.386.2059</td>
					  </tr>
					</tbody>
                </table>
            </div>
            <div className="col-md-7">
              <img alt="pgImage" className="image-border bernadette-image" src={require('../../assets/pgimage.jpg')}/>
              <h5>St Bernadette Parish Calendar</h5><br/><br/>
                <a href="https://calendar.google.com/calendar/embed?src=parish%40stbernadette.ca"><p><b>Click Here for full view</b></p></a>
                <div className="responsiveCal">
				<iframe title="calendar" src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;src=parish%40stbernadette.ca&amp;color=%23D81B60&amp;mode=AGENDA" width="100%" height="900px" frameBorder="0px" scrolling="no"></iframe>
				</div>
			</div>
       </div>
    </div>
  );
}

export default Parish_Groups;