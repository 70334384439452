import React from 'react';
import '../../styles/Mass_Confession.css'

function Mass_Confession() {
  return (
    <div className="Mass_Confession custom-container">
        <div className="row">
            <div className="col-md-7">
                <div>
                    <h3>
                        Mass and Confession
                    </h3>
                     All Masses are in English<br/>
				</div>
				<div>
					<hr/>
					<p>
						Please remember that if you have any COVID symptoms<br/>
						or are feeling unwell you should NOT attend Mass.
						Masks are no longer required in public indoor settings 
						under a public health order, as of March 11, 2022, 
						but we remain kind and understanding to those who wish 
						to continue to use this layer of protection.  
						We are also back to full capacity in the church.<br/>
					</p>
					<p>
					<hr/>
					    <b>Weekend Masses:</b> Saturday 5pm <br/>Sunday 8:30am, 10am, 11:30am<br/><br/>
						<b>Weekday Masses:</b>  9:10am Monday to Friday <br/>9:00am Saturday<br/>
						(<b>Note:</b> Mon-Fri Mass times are at 9:00am when school is not in session,<br/>
						spring break, summer, Christmas breaks)<br/>
						<br/>
						<b>Confession/Sacrament of Reconciliation:</b><br/>
						Weekdays  8:45am – 9:00am<br/>
						Saturdays 9:30am – 10:00am<br/>
						<hr/>
						<a href="https://www.facebook.com/StBernadetteChurchSurreyFacebook"><b>Facebook</b> @StBernadetteChurchSurrey</a><br/> 
						<a href="https://www.instagram.com/stbernadette_church/"><b>Instagram</b> stbernadette_church</a><br/>
						<a href="https://twitter.com/StBeechurch"><b>Twitter</b> @StBeechurch</a>
						<br/>
					If you have additional questions or concerns,<br/>
					please feel free to reach out to the parish office.
					</p>
				</div>
			</div>
            <div className="col-md-5">
                <div className="image-optional-container">
                    <img alt="msImage" className="image-border parish-image" src={require('../../assets/msimage.png')}/>
                </div>
				<br/>
                <div className="calendar_link">
                    <h5>
                        <a href="https://calendar.google.com/calendar/embed?src=parish%40stbernadette.ca">St Bernadette Parish Calendar</a>
                    </h5>
					<hr/>
				<div>
                    <p>
					</p>
                </div>
                </div>
            </div>
       </div>
    </div>
  );
}

export default Mass_Confession;
