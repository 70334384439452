import React from 'react';
import '../../styles/RCIA.css'

function RCIA() {
  return (
    <div className="RCIA custom-container">
        <div className="row">
            <div className="col-md-8">
                <h5>RCIA</h5>
                <p><b>(Rite of Christian Initiation of Adults)</b></p>
                <p className="align-left">Is a wonderful parish process for people who want to find out more about the Catholic Faith. It is intended for people who have never been baptized, had been baptized in another denomination or had been baptized Catholic but had never received any other sacraments.</p>
                <p className="align-left"><b>RCIA</b> sessions run from September to May and are held on Tuesday evenings at 6:30 p.m. in the Church Gathering Area. If you have any questions regarding RCIA please phone the coordinator</p>
                <p><b>Emily Oteyza</b> at <b>604.837.8021 -- <a href="mailto:pepperaso@shaw.ca">pepperaso@shaw.ca</a></b></p>
                <p className="align-left">"Go therfore and make disciples of all nations, baptizing them in the name of the Father and the Son and the Holy Spirit and teaching them to obey everything that I have commanded you."
                <br/><b>(Mt. 28: 19-20)</b></p>
            </div>
            <div className="col-md-4">
              <img alt="church" className="image-border church-image" src={require('../../assets/church2.jpg')}/>
            </div>
       </div>
    </div>
  );
}

export default RCIA;