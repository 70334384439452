import React from 'react';
import '../../styles/Legion_of_Mary.css'

function Legion_of_Mary() {
  return (
    <div className="custom-container">
        <h5>LEGION OF MARY AT ST. BERNADETTE PARISH</h5><br/>
        <p className="align-left">
        The <b>Legion of Mary</b> is a lay apostolic association of Catholics who, with the sanction of the Church and under the powerful leadership of Mary Immaculate, Mediatrix of All Graces, serve the Church and their neighbour on a voluntary basis. The Legion aims to bring Mary to the world as the infallible means of winning the world to Jesus and legionary service is based on the doctrine of the Mystical Body of Christ so that in their fellow members and in those they serve, legionaries seek to have the Person of our Lord once again seen and served by Mary, his Mother.
        <br/><br/>
        Through the visitation of homes and by other means, the Legion sets out to establish a contact of some sort with every soul everywhere. Seeing and serving Christ in the sick and marginalized is another vital part of the legionary apostolate. While not engaging in the giving of material relief, legionaries will often find opportunities to do works of service for the needy.
        <br/><br/>
        The basic unit of the Legion is called a praesidium, which is normally based in a parish. A parish may have more than one praesidium. To be an active legionary it is necessary to be a baptized and practicing Catholic, and to apply for membership in a praesidium, which holds a weekly meeting and allocates a weekly apostolic task to the members, who generally work in pairs. After a successful period of probation, members are called to make the Legionary Promise (this is only applicable for members over 18 years) which is directed to the Holy Spirit. Realising the necessity for a strong support of prayer, the Legion has Auxiliary members, who associate themselves with the Legion by undertaking a service of prayer in its name. The administration of the Legion is carried out through its various councils at local, regional and national level. The central council, the Concilium Legionis Mariae, meets monthly in Dublin.
        <br/><br/>
        Our Lady of Lourdes Praesidium and Queen of Angels Praesidium are both active at St. Bernadette Parish. Our Lady of Lourdes Praesidium meets on <b>Thursday mornings</b> at <b>10:00am</b>, following morning mass, in room D of our Parish Centre. Queen of Angels Praesidium meets on <b>Wednesday evenings</b> at <b>6:30pm</b> in room D of our Parish Centre. <br/>
        <br/>
        If you would like to join the Legion of Mary or would like more information, <br/>please contact us at <b>604-551-0871 or 778-628-2084</b> regarding Our Lady of Lourdes Praesidium,<br/> or <b>Myrna</b> at <b>778-709-4274</b> regarding Queen of Angels Praesidium.
        </p>
        <div className="image-container">
            <img alt="legion is mariae" className="image-border legion-image" src={require('../../assets/LegionisMariae.png')}/>
        </div>
    </div>
  );
}

export default Legion_of_Mary;