import React from 'react';
import '../../styles/TNS.css'

function Liturgy_of_the_word() {
  return (
    <div className="custom-container">
        <h5>LITURGY OF THE WORD WITH CHILDREN AT ST. BERNADETTE PARISH</h5>
        <p className="align-left"><br/>
        St. Bernadette Parish offers <b>Liturgy of the Word with Children</b> at our <b>11:30am Sunday mass.</b><br/><br/>
        <b>What is Children's Liturgy?</b><br/>
        Liturgy of the Word with Children, also called LWC or Children's Liturgy, is a continuation of the Mass presented to young children in a manner related to their level of understanding and experience. It includes Readings, a reflection, the Creed, prayers and an activity.
        <br/><br/>
        <b>What age level is intended for LWC?</b><br/>
        At St. Bernadette Parish, we welcome all children ages 4 through grade 2 to participate. Once the students have received their First Holy Communion, they will remain with their families in the church for the Liturgy of the Word.
        <br/><br/>
        <b>How do the children join?</b><br/>
        After the Opening Prayer, the priest invites the children (ages 4 to grade 2) to the front of the church. The priest passes the Children's Lectionary to the leader who escorts the children to the place for their Liturgy of the Word.
        <br/><br/>
        <b>Is registration necessary?</b><br/>
        Children are welcome to attend Children's Liturgy on any Sunday. No registration or payment is required. Parents are free to come and see with their children. Volunteers are also always welcome!
        <br/><br/>
        <b>Where is LWC celebrated?</b><br/>
        The children go to a separate but nearby room in our Parish Centre where they can hear the Word of God, participate in the liturgy and engage in lesson-appropriate movement. Although the children are in a separate room, they are not leaving Mass, just continuing the liturgy elsewhere.
        <br/><br/>
        <b>When do they return to the main assembly?</b><br/>
        The children return during the Offertory. At this point, the Liturgy of the Word is finished and, because the main assembly is usually engaged in song, the noise and activity of the returning children is not disruptive. It is important that they be present during the Liturgy of the Eucharist.
        <br/><br/>
        <b>What happens in a typical Children's Liturgy session?</b><br/>
        The leader welcomes and settles the children. Reading from the Children's Lectionary, the leader proclaims the Word of God (usually the First Reading and Gospel of that Sunday). Then the leader engages the children in an interactive talk about the Gospel. Sometimes a skit, song or other activity is used. After the Gospel reflection, everyone joins together in a simplified Creed and the Prayers of the Faithful. Many parishes provide the children with handouts to take home to help reinforce the message they have learned.
        <br/><br/>
        <b>What is the purpose of LWC?</b><br/>
        Children's Liturgy is designed to give children a better understanding of the Mass and the readings. It provides a foundation for learning and participation that will remain with them throughout their life.
        <br/><br/>
        <b>What are some of the benefits to LWC?</b><br/>
        Children want to come to church every Sunday to attend Children's Liturgy. They fell welcomed and accepted by the church community. Through active participation, they learn the rituals and traditions of the Mass and develop a love for the Church and the Catholic faith.
        <br/><br/>
        <b>What are the qualifications of the leaders?</b><br/>
        The leaders must be practicing Catholics who know and understand the faith. They should love children and be excited about sharing their faith. It is recommended that they take the Liturgy of the Word Orientation & Training course offered through the Archdiocese Catechetics Office. All leaders and assistants must successfully complete the parish screening process, including a police record check in compliance with the Archdiocesan Safe Environment Policies.
        <br/><br/>
        <b>What is the difference between LWC and catechesis?</b><br/>
        LWC is liturgy; it is a continuation of the Mass. Catechism is formal religious education, usually in a classroom. LWC lasts about 20 minutes; classroom catechesis takes about an hour.
        <br/><br/>
        If you are interested in volunteering as a leader or as an assistant in this ministry, please contact the parish office.
        </p>
    </div>
  );
}

export default Liturgy_of_the_word;